import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')];

export const server_loads = [0];

export const dictionary = {
	"/verify-email": [~20],
	"/[lang=lang]": [~2],
	"/[lang=lang]/get-started": [~4],
	"/[lang=lang]/login": [~5],
	"/[lang=lang]/map": [~6],
	"/[lang=lang]/map/fix": [7],
	"/[lang=lang]/onboarding": [~8],
	"/[lang=lang]/post/edit": [~9],
	"/[lang=lang]/post/new": [~10],
	"/[lang=lang]/privacy-url": [11],
	"/[lang=lang]/profile": [12],
	"/[lang=lang]/profile/[uid]": [~13],
	"/[lang=lang]/profile/[uid]/edit": [~14],
	"/[lang=lang]/registration": [~15],
	"/[lang=lang]/welcome": [16],
	"/[lang=lang]/why-competition-guidelines": [17],
	"/[lang=lang]/why-freedive": [18],
	"/[lang=lang]/why-freedive/submit": [~19],
	"/[lang=lang]/[username]": [~3]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';